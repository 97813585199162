import { getExecute } from '@/utils/dialogExecute'

export const rules = {
  opusName: [
    { required: true, message: '请输入作品名称', trigger: 'change' }
  ],
  type: [
    { required: true, message: '请输入作品类型', trigger: 'change' }
  ],
  contact: [
    { required: true, message: '请输入联系人', trigger: 'change' }
  ],
  mobile: [
    { required: true, message: '请输入联系人手机号', trigger: 'change' }
  ],
  companyName: [
    { required: true, message: '请输入制作公司', trigger: 'change' }
  ],
  certificate: [
    { required: true, message: '请上传拍摄许可证', trigger: 'change' }
  ],
  publicity: [
    { required: true, message: '请上传公示表文件', trigger: 'change' }
  ],
  shootLocation: [
    { required: true, message: '请输入拍摄场地', trigger: 'change' }
  ],
  shootTime: [
    { required: true, message: '请选择拍摄时间', trigger: 'change' }
  ]
}

export const CASE_STATUS_MAP = [
  {
    label: '新增',
    key: 'NEW'
  },
  {
    label: '审核通过',
    key: 'SUCCESS'
  },
  {
    label: '审核失败',
    key: 'FAIL'
  }
]

export function resetCase() {
  return {
    opusName: '',
    type: '',
    contact: '',
    mobile: '',
    companyName: '',
    certificate: '',
    publicity: '',
    shootLocation: '',
    shootTime: ''
  }
}

const singleVue = {
  addOrEditCase: false,
  showImg: false
}

const strategyMap = {
  addOrEditCase: () => import('./components/addOrEditCase.vue'), // 日志显示
  showImg: () => import('./components/showImg.vue') // 日志显示
}

export const execute = getExecute(singleVue, strategyMap)
